<template>



  <carousel class="article-slider" v-if="this.articles && this.articles.length > 0" :settings="settings"
            :breakpoints="breakpoints">
    <slide v-for="article in articles" :key="article.id">
      <PodcastBlockSlider :article="article"/>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>
</template>

<script>
import {Carousel, Slide, Pagination, Navigation} from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';
import PodcastBlock from "@/views/Audio/Part/PodcastBlock";
import PodcastSection from "@/views/Audio/Part/PodcastSection";
import PodcastBlockSlider from "@/views/Audio/Part/PodcastBlockSlider.vue";

export default {
  name: "PodcastSlider",
  components: {PodcastBlockSlider},
  extends: PodcastSection,
  data() {
    return {

      settings: {
        itemsToShow: 1,
        snapAlign: 'start',
      },
      // breakpoints are mobile first
      // any settings not specified will fallback to the carousel settings
      breakpoints: {
        // // 700px and up
        // 700: {
        //   itemsToShow: 2,
        //   snapAlign: 'center',
        // },
        // // 1024 and up
        // 1024: {
        //   itemsToShow: 4,
        //   snapAlign: 'start',
        // },
        // // 1024 and up
        // 1224: {
        //   itemsToShow: 4,
        //   snapAlign: 'start',
        // },
      },
    }
  }
}
</script>

<style  lang="scss">

.carousel__pagination{
  position: absolute;

  top: 12px;
  left: 0;
  right: 0;

  padding: 0;
  margin: 0;

  &-item{

  }
  &-button{
    width: 12px;
    height: 12px;

    border-radius: 6px;

    background-color: rgba(29,76,255,1) !important;
    //background-color: rgba(255,255,255,.5) !important;

    &--active{
      background-color: rgb(14, 37, 123) !important;
      width: 24px;

    }
  }
}


</style>
<style scoped lang="scss">

.image {
  display: block;
  width: 100%;
  border-radius: 8px;

  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  overflow: hidden;

  position: relative;

  &-main {
    background-image: url('/img/mission.jpg');
    padding-bottom: 67%;
    margin-bottom: 5%;
  }

  &__title {

    position: absolute;

    left: 30px;
    bottom: 10px;

    //text-transform: uppercase;

    line-height: 20px;
    font-size: 32px !important;
    font-weight: bold;
    white-space: nowrap;
    font-family: "Joane";


    color: white;
    text-shadow: 1px 1px 10px #000;

  }
  @media screen and (max-width: 720px){
    &__title{
      font-size: 18px !important;
    }
  }
}

.article-slider{
  height: auto;
  @media screen and (max-width: 720px){
    height: auto;

  }
}

</style>
