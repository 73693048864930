<template>

    <router-link class="people-line"
                 :to="'/'+user.url_full"
    >
      <div class="people-line__thumb"
           :style="{ backgroundImage: `url(${user.avatar_url})` }"></div>
      <h4 class="people-line__name" v-if="!hide_name">
        {{user.firstname}}
        {{user.lastname}}
      </h4>
      <span class="people-line__position" v-if="!hide_name">&nbsp;{{user.position}}</span>
    </router-link>
</template>

<script>
export default {
  props: ['user','hide_name'],
  name: "PeopleLine"
}
</script>

<style scoped lang="scss">

.people-line{

  width: 100%;
  display: block;
  flex-direction: column;
  text-align: center;

  margin-bottom: 24px;

  &__thumb{
    width: 100%;
    height: 0;
    padding-bottom: 100%;

    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;

    margin-bottom: 12px;
  }
  &__name{
    margin-top: 0;
    margin-bottom: 0;
    color: #000;
    font-weight: 700;
    font-size: 18px;
    font-family: "Joane";

  }
  &__position{
    color: #ccc;
  }
}

</style>